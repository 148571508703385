import React from 'react';
import './App.css';
import './layouts/Footer.js'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from 'react-redux';
import {store, persistor} from './redux/store/store';
import { PersistGate } from 'redux-persist/integration/react';
import Index from './components/Index';
import About from './components/About';
import Contact from './components/Contact';
import TeamDistribution from './components/TeamDistribution.js';
import Rota from './components/Rota.js';
import MediaBooking from './components/MediaBooking';
import Onboarding from './components/Onboarding';
import OnboardingDocument from './components/OnboardingDocument';
import Events from './components/Events';
import TeamAbsences from './components/TeamAbsences'
import ProductionReady from './components/ProductionReady'
import SkillsMatrix from './components/SkillsMatrix';
import TeamBirthdays from './components/TeamBirthdays.js';
import EventDetails from './components/EventDetails.js';
import MemberRegistration from './components/MemberRegistration.js';
import ProcessImprovements from './components/ProcessImprovements.js';
import TeamMemberProfile from './components/TeamMemberProfile.js';
import BestPractices from './components/BestPractices.js';
import BroadcastEmail from './components/BroadcastEmail.js';
import MediaEvents from './components/MediaEvents.js';
import ManageUsers from './components/ManageUsers.js';
import ManageEvents from './components/ManageEvents.js';
import MemberProfileUpdate from './components/MemberProfileUpdate.js';
import MediaClockIn from './components/MediaClockIn.js';
import EventDetailsAdmin from './components/EventDetailsAdmin.js';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
      <div className="App">
        <Router>
          <Routes>
            <Route path='/' element={<Index/>} />
            <Route path='/about' element={<About/>} />
            <Route path='/contact' element={<Contact/>} />
            <Route path='/teamdistribution' element={<TeamDistribution/>} />
            <Route path='/rota' element={<Rota/>} />
            <Route path='/mediabooking' element={<MediaBooking/>} />
            <Route path='/onboarding' element={<Onboarding/>} />
            <Route path='/documentation' element={<OnboardingDocument/>} />
            <Route path='/events' element={<Events/>} />      
            <Route path='/mevents' element={<MediaEvents/>} />  
            <Route path='/eventsdetails' element={<EventDetails/>} />
            <Route path='/absences' element={<TeamAbsences/>} /> 
            <Route path='/readinesscheck' element={<ProductionReady/>} />  
            <Route path='/skillsmatrix' element={<SkillsMatrix/>} />        
            <Route path='/birthdays' element={<TeamBirthdays/>} />      
            <Route path='/registration' element={<MemberRegistration/>} />
            <Route path='/improvements' element={<ProcessImprovements/>} />  
            <Route path='/memberprofile' element={<TeamMemberProfile/>} />
            <Route path='/bestpractices' element={<BestPractices/>} />
            <Route path='/eventdetailsadmin' element={<EventDetailsAdmin/>} />
            <Route path='/clockin' element={<MediaClockIn/>} />
            <Route path='/broadcastemail' element={<BroadcastEmail/>} />
            <Route path='/manageusers' element={<ManageUsers/>} />
            <Route path='/manageevents' element={<ManageEvents/>} />
            <Route path='/profileupdate' element={<MemberProfileUpdate/>} />
          </Routes>
        </Router>
      </div>
      </PersistGate>
      </Provider>
  );
}

export default App;
