import React, { Fragment, useState, useEffect } from "react";
import Topbar from "../layouts/TopBar";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import AbsenceResources from "../resources/AbsenceResources";
import UserResources from "../resources/UserResources";
import { toast } from "react-toastify";
import moment from "moment/moment";
import Select from 'react-select';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { connect } from "react-redux";
import { loginSuccess, logout } from "../redux/actions/LoggedInAction";
import {ACCESS_DENIED} from '../Messages'
import EventResources from "../resources/EventResources";

function ManageEvents({isLoggedIn, loggedInUser}){  
  const [events, setEvents] = useState([])
  const eventResources = new EventResources()

    const loadEvents = () => {
        
        return eventResources.get_admin()
    }
     
      // Function to handle button clicks
      const handleApproveEventButtonClick = (ev) => { 
        eventResources.admin_approve_event_post(ev).then(res => {
            if (res.error.length < 1) {
                alert("Event Approved")
                setTimeout(() => {
                    window.location.href = window.location.origin + '/manageevents';
                  }, 200);
            }
            else {
                alert("Approval Failed.")
            }
            }).catch((err) => {
                toast.error("Network Error: " + err);
            });
      };
  
      // Function to handle button clicks
      const handleRefuseEventButtonClick = (ev) => {      
        eventResources.admin_refuse_event_post(ev).then(res => {
            if (res.error.length < 1) {
                alert("Event Refused. Speak to Seyi to Completely remove if needed")
                setTimeout(() => {
                    window.location.href = window.location.origin + '/manageevents';
                  }, 200);
            }
            else {
                alert("Approval Process Failed.")
            }
            }).catch((err) => {
                toast.error("Network Error: " + err);
            });
      
  
      };  
      
      const handleDisableEventButtonClick = (user) => {      
        eventResources.admin_disable_event_post(user).then(res => {
            if (res.error.length < 1) {
                alert("Event Disabled. Speak to Seyi to Completely remove if needed")
                setTimeout(() => {
                    window.location.href = window.location.origin + '/manageevents';
                  }, 200);
            }
            else {
                alert("Approval Process Failed.")
            }
            }).catch((err) => {
                toast.error("Network Error: " + err);
            });
      
  
      };  
     

    useEffect(() => {
        loadEvents().then(response => { 
            setEvents(response['body']); 
            toast.success("Successfully got data" )
        }).catch((err) => {
            toast.error("Network error: " + err)
        });
    }, []);

    
    return(
        <Fragment>
            <Topbar/>

            <div className="hero-area">
            <div className="page-banner parallax" style={{backgroundColor:"#003165"}}>
                    <div className="container">
                        <div className="page-banner-text">
                        {(isLoggedIn && loggedInUser['role.name'] === 'admin')?
                                <h1 style={{color:"#ffa800"}} className="block-title"><strong>All Events</strong></h1>
                                :
                                <h1 style={{color:"#ffa800"}} className="block-title"><strong>{ACCESS_DENIED}</strong></h1>
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
            

            <div id="main-container">
                {
                    (isLoggedIn && loggedInUser['role.name'] === 'admin') ? 
                
                <div className="content">
                    <div className="container">                    
                        <h3 style={{color: "#ffa800"}}><strong>Events Approved</strong></h3>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                <TableRow>
                                    <TableCell><strong></strong></TableCell>
                                    <TableCell><strong>Name</strong></TableCell>
                                    <TableCell><strong>Date</strong></TableCell>
                                    <TableCell><strong>Event Contact</strong></TableCell>
                                    <TableCell><strong></strong></TableCell>
                                    <TableCell><strong></strong></TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                                                      
                                {events.map((ev) => (
                                    
                                    
                                    ev['enabled'] === '1' ?
                                    
                                    <TableRow key={ev['name']}>
                                    <TableCell><img src={ev['image']} style={{width: "20px", height:"20px"}} alt=""/></TableCell>
                                    <TableCell>{ev['name']}</TableCell>
                                    <TableCell>{moment(ev['event_date']).format('DD/MM/YYYY')}</TableCell>
                                    <TableCell>{ev['contact_name']}</TableCell>
                                    <TableCell><a href={"/eventdetailsadmin?id=" + ev['id']}>View Event</a> </TableCell>                                     
                                    <TableCell>{ loggedInUser !== null && loggedInUser['role.name'] === 'admin'? <button style={{marginLeft: "10px"}} onClick={() => handleDisableEventButtonClick(ev)} >Disable Event</button> : <p></p>}</TableCell>                                     
                                    </TableRow> :
                                    <></>
                                ))
                                }
                                </TableBody>
                            </Table>
                        </TableContainer>    
                        <div className="spacer-40"></div>

                        <h3 style={{color: "#ffa800"}}><strong>Events Pending Approval</strong></h3>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                <TableRow>
                                <TableCell><strong></strong></TableCell>
                                    <TableCell><strong>Name</strong></TableCell>
                                    <TableCell><strong>Date</strong></TableCell>
                                    <TableCell><strong>Event Contact</strong></TableCell>
                                    <TableCell><strong></strong></TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {events.map((ev) => (
                                    
                                    ev['enabled'] === '0' ? 
                                    <TableRow key={ev['firstname']}>
                                    <TableCell><img src={ev['image']} style={{width: "20px", height:"20px"}} alt=""/></TableCell>
                                    <TableCell>{ev['name']}</TableCell>
                                    <TableCell>{moment(ev['event_date']).format('DD/MM/YYYY')}</TableCell>
                                    <TableCell>{ev['contact_name']}</TableCell>
                                    <TableCell><a href={"/eventdetailsadmin?id=" + ev['id']}>View Event</a> </TableCell>                                      
                                    <TableCell>{ loggedInUser !== null && loggedInUser['role.name'] === 'admin'? <button style={{marginLeft: "10px"}} onClick={() => handleApproveEventButtonClick(ev)} >Approve Event</button> : <p></p>}</TableCell>                                     
                                    <TableCell>{ loggedInUser !== null && loggedInUser['role.name'] === 'admin'? <button style={{marginLeft: "10px"}} onClick={() => handleRefuseEventButtonClick(ev)} > Refuse Event</button> : <p></p>}</TableCell>                                     
                                    </TableRow>:
                                   <></>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer> 
                            
                           
                        

                            
                    </div>
                </div> : <></>
        }
            </div>

        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return{
       isLoggedIn: state.authReducer.isLoggedIn,
       loggedInUser: state.authReducer.user
    };
    
};

const mapDispatchToProps = {
    logout
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageEvents);