import React, { Fragment, useState, useEffect } from "react";
import { connect } from 'react-redux';
import Topbar from "../layouts/TopBar";
import Footer from "../layouts/Footer";
import { ToastContainer, toast } from 'react-toastify';
import { loginSuccess, logout } from "../redux/actions/LoggedInAction";
import LocationResources from "../resources/LocationResources";
import UserResources from "../resources/UserResources";
import RotaResources from "../resources/RotaResources";
import Select from 'react-select';
import moment from "moment";
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

function validate(firstname, lastname, email, password, phone, address1, city, post_code, country, date_of_joining, date_of_birth, location_id, about, competencies) {
    return {
      firstname: firstname.length === 0,
      lastname: lastname.length === 0,
      email: email.length === 0,
      password: password.length === 0,
      phone: phone === 0,
      address1: address1.length === 0,
      city: city.length === 0,
      post_code: post_code.length === 0,
      country: country.length === 0,
      date_of_joining: date_of_joining.length === 0,
      date_of_birth: date_of_birth.length === 0,
      location_id: location_id.length === 0,
      about: about.length === 0,
      competencies: competencies.length === 0,
    };
  }

function MemberProfileUpdate({isLoggedIn, loggedInUser, logout}){
    const [user, setUser] = useState([])
    const [current_email, setCurrentEmail] = useState('')
    const [locations, setLocations] = useState([])
    const [selectedJoiningDate, setSelectedJoiningDate] = useState(null); 
    const [rotaRoles, setRotaRoles] = useState([])
    const [selectedMediaRoles, setSelectedMediaRoles] = useState([]);
    const [birthday, setBirthday] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [isFortifyChecked, setIsFortifyChecked] = useState(false);
    const [isConnectChecked, setIsConnectChecked] = useState(false);
    const [isServeChecked, setIsServeChecked] = useState(false);
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        address1: '',
        city: '',
        post_code: '',
        phone: '',
        country: '',
        about: '',
        date_of_joining: null, // Assuming it's a Date object, initialize it appropriately
        date_of_birth: null, // Assuming it's a Date object, initialize it appropriately
        location_id: '',
        email: '',
        new_email:'',
        password: '',
      });

      const [formLoginData, setformLoginData] = useState({
        email: '',
        password: '',
      });
    
    
    const userImageAlbumId = '2hwMk0B' // imgur album id
    const locationResources = new LocationResources()
    const userResources = new UserResources()
    const rotaResources = new RotaResources()
    const today = new Date();
    

    const handleDateOfJoiningChange = (date) => {
        setSelectedJoiningDate(date);
    };

    const handleBirthdayChange = (date) => {
        setBirthday(date);
    };    

    const handleFortifyCheckboxChange = (event) => {
        setIsFortifyChecked(event.target.checked);
    };

    const handleConnectCheckboxChange = (event) => {
        setIsConnectChecked(event.target.checked);
    };

    const handleServeCheckboxChange = (event) => {
        setIsServeChecked(event.target.checked);
    };

    const loadLocations = () => {
        return locationResources.get()
    }

    const loadRotaRoles = () => {
        return rotaResources.get_rota_roles()
    }

    // ToDO: renable this method to update state with date
    // const handleDateChange = (name, date) => {
    //     setFormData((prevData) => ({
    //       ...prevData,
    //       [name]: date,
    //     }));
    //   };

    const handleCountrySelection = (selectedCountry) => {
        setFormData((prevData) => ({
          ...prevData,
          country: selectedCountry['value'],
        }));
      };
      
    const handleSelection = (selectedOptions) => {
        setFormData((prevData) => ({
          ...prevData,
          location_id: selectedOptions['value'],
        }));
      };

      

    const handleMediaSkillsSelection = (skills) => {
        setSelectedMediaRoles(skills)
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
    
      const handleLoginInputChange = (e) => {
        const { name, value } = e.target;
        setformLoginData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImageFile(file);
      };  

      const uploadToImgur = async () => {
        if (!imageFile) {
            alert('Please select a file.');
            return;
        }    

        try {
          const formData = new FormData();
          formData.append('image', imageFile);
          formData.append('album', userImageAlbumId);
    
          const response = await fetch('https://api.imgur.com/3/image', {
            method: 'POST',
            headers: {
              Authorization: `Bearer ` + process.env.REACT_APP_Bearer_Token.toString(), 
            },
            body: formData,
          });
    
          const data = await response.json();
    
          if (data.success) {
            setImageUrl(data.data.link);   
            //addToMemberAlbum(data.data.id)         
            alert('Image Successfully uploaded. Now complete the rest of the form and submit.');
          } else {
            alert('Error Uploading Image, please try again');
          }
        } catch (error) {
          alert('Error Uploading Image, please try again');
        }
      };   
      
    const canBeSubmitted = (dataToSubmit) => {
        const errors = validate(dataToSubmit['firstname'], dataToSubmit['lastname'], dataToSubmit['email'], dataToSubmit['password'],  dataToSubmit['phone'], dataToSubmit['address1'], 
        dataToSubmit['city'], dataToSubmit['post_code'], dataToSubmit['country'],dataToSubmit['date_of_joining'],dataToSubmit['date_of_birth'],dataToSubmit['location_id'],
        dataToSubmit['about'], dataToSubmit['competencies']);
        const isDisabled = Object.keys(errors).some(x => errors[x]);
        return !isDisabled;
    }   

    

    const handleSubmit = (e) => {
        e.preventDefault();
        let submitFormData = formData
        // ToDo: Fix this hack: get all data from state
        let media_roles =[]        
        selectedMediaRoles.map((role) => (
            media_roles.push(role['value'])
        ))
        const mediaRoles = media_roles.join(', ')
        submitFormData['competencies'] = mediaRoles
        //submitFormData['image'] = imageUrl
        if(selectedJoiningDate !== null)
            submitFormData['date_of_joining'] = moment( selectedJoiningDate ).format('YYYY-MM-DD HH:mm:ss') 
        if(birthday !== null)
            submitFormData['date_of_birth'] = moment( birthday ).format('YYYY-MM-DD HH:mm:ss') 
        submitFormData['done_connect'] =  isConnectChecked
        submitFormData['done_fortify'] = isFortifyChecked 
        submitFormData['done_serve'] = isServeChecked
        submitFormData['email'] = user['email']
        // if (!canBeSubmitted(submitFormData)){
        //     alert("Remember to fill out the mandatory fields")
        //     return
        // }
        userResources.admin_put(submitFormData).then(res => {
            if (res.error.length < 1) {
                alert("Your Profile has been updated")
                setTimeout(() => {
                    window.location.href = window.location.origin + '/profileupdate';
                  }, 200);
            }else if(res.error === 'Email already in use') {
                alert("Registration failed, Email Already in Use.")
            }
            else {
                alert("Registration Failed, please check details entered.")
            }
            }).catch((err) => {
                toast.error("Network Error: " + err);
            });

       
      };

    const loadUsers = (id, loggedInID) => {        
        return userResources.get_admin_single_user(id, loggedInID)
    }

    useEffect(() => {  
        if (loggedInUser !== null){
            loadUsers(loggedInUser['id'], loggedInUser['id']).then(response => { 
                setUser(response['body']); 
                setCurrentEmail(user['email'])
                toast.success("Successfully got data" )
            }).catch((err) => {
                toast.error("Network error: " + err)
            });
        }               

        loadLocations().then(response => { 
            setLocations(response['body']); 
            toast.success("Successfully got data" )
        }).catch((err) => {
            toast.error("Network error: " + err)
        });  

        loadRotaRoles().then(response => { 
            setRotaRoles(response['body']); 
            toast.success("Successfully got data" )
        }).catch((err) => {
            toast.error("Network error: " + err)
        });  

    }, []);
    
    let mediaLocations = []  
    locations.map((loc, index) => (
        mediaLocations.push({value: loc['id'], label: loc['name'] })
    ))

    let mediaRoles = []  
    rotaRoles.map((rotaRole, index) => (
        mediaRoles.push({value: rotaRole['name'], label: rotaRole['name'] })
    ))
    mediaRoles.push({value: 'Photography', label: 'Photography' })
    mediaRoles.push({value: 'Camera close up', label: 'Camera close up' })
    mediaRoles.push({value: 'Roaming Camera', label: 'Roaming Camera' })
    mediaRoles = mediaRoles.filter(role => role.value !== 'Camera 1');
    mediaRoles = mediaRoles.filter(role => role.value !== 'Roam 1');
    mediaRoles = mediaRoles.filter(role => role.value !== 'Roam 2');

    let countries= []
    countries.push({value: 'United Kingdom', label: 'United Kingdom' })
    countries.push({value: 'Nigeria', label: 'Nigeria' })

    return(
        <Fragment>
            <Topbar/>

            <div className="hero-area">
            {/* <div className="page-banner parallax" style={{backgroundImage: "url(assets/images/testjpeg1280.jpg)"}}> */}
            <div className="page-banner parallax" style={{backgroundColor:"#003165"}}>
                    <div className="container">
                        <div className="page-banner-text">
                            <h1 style={{color:"#ffa800"}} className="block-title"><strong>Manage Your Details</strong></h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className="spacer-10"></div>

            {/* <!-- Main Content --> */}
            <div id="main-container">
                <div className="content">               

                <div class="container">      
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell style={{alignContent : 'center'}}><img src={user['image']} style={{width: "200px", height:"200px"}} alt=""/></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>First Name</strong></TableCell>
                                    <TableCell>{user['firstname']}</TableCell>
                                    <TableCell>
                                        <input 
                                            type="text" 
                                            name="firstname" 
                                            placeholder="Edit first name" 
                                            value={formData.firstname}
                                            onChange={handleInputChange}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Last Name</strong></TableCell>
                                    <TableCell>{user['lastname']}</TableCell>
                                    <TableCell>
                                        <input 
                                            type="text" 
                                            name="lastname"
                                            value={formData.lastname}
                                            onChange={handleInputChange}
                                            placeholder="Edit last name" 
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Address</strong></TableCell>
                                    <TableCell>{user['address1']}</TableCell>
                                    <TableCell>
                                        <input 
                                            type="text" 
                                            name="address1"
                                            value={formData.address1}
                                            onChange={handleInputChange}
                                            placeholder="Edit Address" 
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>City</strong></TableCell>
                                    <TableCell>{user['city']}</TableCell>
                                    <TableCell>
                                        <input 
                                            type="text" 
                                            name="city"
                                            value={formData.city}
                                            onChange={handleInputChange}
                                            placeholder="Edit City" 
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Postcode</strong></TableCell>
                                    <TableCell>{user['post_code']}</TableCell>
                                    <TableCell>
                                        <input 
                                            type="text" 
                                            name="post_code"
                                            value={formData.post_code}
                                            onChange={handleInputChange}
                                            placeholder="Edit Postcode" 
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Phone</strong></TableCell>
                                    <TableCell>{user['phone']}</TableCell>
                                    <TableCell>
                                        <input 
                                            type="number" 
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleInputChange}
                                            placeholder="Edit Phone number" 
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Joined Media Team</strong></TableCell>
                                    <TableCell>{moment( user['date_of_joining']).format('MMMM YYYY')}</TableCell>
                                    <TableCell>
                                        <DatePicker
                                            name="dateOfJoining"
                                            placeholderText="When you joined the team"
                                            value={selectedJoiningDate}
                                            onChange={handleDateOfJoiningChange}
                                            maxDate={today}
                                            dateFormat="yyyy-MM-dd" // Set desired date format
                                            /> 
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Bio</strong></TableCell>
                                    <TableCell>{user['about']}</TableCell>
                                    <TableCell>
                                    <textarea cols="4" rows="4" id="message" name="about" value={formData.about}
                                                    onChange={handleInputChange}  className="form-control input-lg" 
                                                    placeholder="Short Bio - Why you joined media, Career, Skillset, interests etc">                                                        
                                        </textarea>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Media Skills</strong></TableCell>
                                    <TableCell>{user['competencies']}</TableCell>
                                    <TableCell>
                                        <Select
                                            name='media-skills'
                                            id="media-skills"
                                            classNamePrefix='select-css-tag'
                                            isMulti
                                            //value={selectedOption}
                                            onChange={handleMediaSkillsSelection}
                                            options={mediaRoles}
                                            placeholder="Select all that apply"
                                                />  
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Primary Location</strong></TableCell>
                                    <TableCell>{user['location.name']}</TableCell>
                                    <TableCell>
                                            <Select
                                                name='locations'
                                                id="locations"
                                                classNamePrefix='select-css-tag'
                                                //value={selectedOption}
                                                onChange={handleSelection}
                                                options={mediaLocations}
                                                placeholder="Locations"
                                                />  
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Birthday</strong></TableCell>
                                    <TableCell>{moment( user['date_of_birth']).format('DD MMMM')}</TableCell>
                                    <TableCell>
                                    <label>Choose any Year: </label>
                                            <DatePicker
                                                    name="birthday"
                                                    placeholderText="Pick Date"
                                                    value={birthday}
                                                    onChange={handleBirthdayChange}
                                                    maxDate={today}
                                                    dateFormat="yyyy-MM-dd" // Set desired date format
                                                />  
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Done Connect</strong></TableCell>
                                    <TableCell>{user['done_connect'] === 'True' ? 'Yes' : 'No'}</TableCell>
                                    <TableCell>
                                        <input
                                            type="checkbox"
                                            checked={isConnectChecked}
                                            onChange={handleConnectCheckboxChange}
                                            />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Done Fortify</strong></TableCell>
                                    <TableCell>{user['done_fortify'] === 'True' ? 'Yes' : 'No'}</TableCell>
                                    <TableCell>
                                        <input
                                            type="checkbox"
                                            checked={isFortifyChecked}
                                            onChange={handleFortifyCheckboxChange}
                                            />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Done Serve</strong></TableCell>
                                    <TableCell>{user['done_serve'] === 'True' ? 'Yes' : 'No'}</TableCell>
                                    <TableCell>
                                        <input
                                            type="checkbox"
                                            checked={isServeChecked}
                                            onChange={handleServeCheckboxChange}
                                            />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Email</strong></TableCell>
                                    <TableCell>{user['email']}</TableCell>
                                    <TableCell>
                                        <input type="email" className="form-control"
                                            name="new_email"
                                            value={formData.new_email}
                                            onChange={handleInputChange}
                                            />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Password</strong></TableCell>
                                    <TableCell>{"*****"}</TableCell>
                                    <TableCell>
                                        <input type="password" className="form-control"
                                            name="password"
                                            value={formData.password}
                                            onChange={handleInputChange}
                                            />
                                    </TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                
                                </TableBody>
                            </Table>
                        </TableContainer>      

                        <div className="spacer-30"></div>                                        
                            <input style={{backgroundColor:"#003165"}} type="submit" className="btn btn-primary" value="Submit Updates" onClick={handleSubmit}/>
                            <div className="spacer-10"></div>
                        </div>
                    
                </div>
            </div>
            <div className="spacer-75"></div>
            <div className="spacer-75"></div>
            <div className="spacer-75"></div>
            <div className="spacer-75"></div>
            <div className="spacer-75"></div>
            <div style={{backgroundColor: "#d80000"}} className="accent-bg padding-tb20 cta-fw">
                    <div className="container">
                        <a style={{backgroundColor: "#d80000"}} href="#" target="_blank" className="btn btn-default btn-ghost btn-light btn-rounded pull-right">Delete your Profile</a>
                        <h4><strong>Please be certain</strong></h4>
                    </div>
            </div>
            <div className="spacer-10"></div>
            <div className="spacer-75"></div>
            <div className="spacer-75"></div>
            <div className="spacer-75"></div>
            <div className="spacer-75"></div>
            <Footer/>     
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return{
       isLoggedIn: state.authReducer.isLoggedIn,
       loggedInUser: state.authReducer.user
    };
    
};

const mapDispatchToProps = {
    logout
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberProfileUpdate);