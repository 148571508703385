import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { loginSuccess, logout } from "../redux/actions/LoggedInAction";
import Topbar from "../layouts/TopBar";
import Footer from "../layouts/Footer";
import EventResources from "../resources/EventResources";
import { toast } from "react-toastify";
import moment from "moment/moment";

function Events({isLoggedIn, loggedInUser}){
    const [events, setEvents] = useState([])

    const loadEvents = () => {
        let resources = new EventResources()
        return resources.get()
    }

    useEffect(() => {
        loadEvents().then(response => { 
            setEvents(response['body']); 
            toast.success("Successfully got data" )
        }).catch((err) => {
            toast.error("Network error: " + err)
        });
    }, []);

    return(
        <Fragment>
            <Topbar/>

            <div className="hero-area">
            <div className="page-banner parallax" style={{backgroundColor:"#003165"}}>
                    <div className="container">
                        <div className="page-banner-text">
                            <h1 style={{color:"#ffa800"}} className="block-title"><strong>Events</strong></h1>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Main Content --> */}
            <div id="main-container">
                <div className="content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 content-block">
                                <ul className="events-list">
                                    {events.map((event) => (    
                                        event['team_members_only'] === 'False' ?
                                        
                                        <li className="event-list-item">                                             

                                            <span style={{backgroundColor: "#ffa800"}} className="event-date">
                                            <span className="date">{moment(event['event_date']).format('DD')}</span>
                                            <span className="month">{moment(event['event_date']).format('MMM')}</span>
                                            <span className="year">{moment(event['event_date']).format('YYYY')}</span>
                                            </span>
                                               
                                        
                                            <div className="event-list-cont">
                                                <span className="meta-data">{moment(event['start_time']).format('dddd, HH:mm')} - {moment(event['end_time']).format('HH:mm')}</span>
                                                <h4 className="post-title"><a href={"/eventsdetails?id=" + event['id']}>{event['name']}</a></h4>
                                                <p>{event['description']}</p>
                                                <a href={"/eventsdetails?id=" + event['id']} className="btn btn-default">Details</a>
                                            </div>
                                        </li>  : <></>    
                                    ))}                                                                   
                                </ul>                             
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>

        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return{
       isLoggedIn: state.authReducer.isLoggedIn,
       loggedInUser: state.authReducer.user
    };
    
};

const mapDispatchToProps = {
    logout
};

export default connect(mapStateToProps, mapDispatchToProps)(Events);
