import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { loginSuccess, logout } from "../redux/actions/LoggedInAction";
import Topbar from "../layouts/TopBar";
import Footer from "../layouts/Footer";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'react-datetime/css/react-datetime.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import {ACCESS_DENIED} from '../Messages'


function MediaClockIn({isLoggedIn, loggedInUser, logout}){
    const [time, setTime] = useState(new Date().toLocaleTimeString());
    
    useEffect(() => {       
        const timer = setInterval(() => {
        setTime(new Date().toLocaleTimeString()); 
        }, 1000);
        // Cleanup interval on component unmount
        return () => clearInterval(timer);
        
    }, []);
    
    

    return(
        <Fragment>
            <Topbar/>

            <div className="hero-area">
            <div className="page-banner parallax" style={{backgroundColor:"#003165"}}>
                    <div className="container">
                    <div className="page-banner-text">
                            {isLoggedIn ?
                                <h1 style={{color:"#ffa800"}} className="block-title"><strong>Media Clock IN</strong></h1>
                                :
                                <h1 style={{color:"#ffa800"}} className="block-title"><strong>{ACCESS_DENIED}</strong></h1>
                            }
                            
                        </div>
                    </div>
                </div>
            </div>

            <div className="spacer-10"></div>

            {/* <!-- Main Content --> */}
            <div id="main-container">
            <div style={{display: "flex",justifyContent: "center",alignItems: "center",width: "100%", paddingRight:"200px"}}
>
                <div className="content" style={{ width: "60%", height: "auto",display: "grid", placeItems: "center",padding: "20px", }}>
                    <div className="container">
                        <div className="text-align-center">
                            <h2><strong>CURRENT TIME: </strong><strong style={{color:"#ffa800"}}>{time}</strong></h2>
                        </div>
                    </div>
                                        
                <div className="spacer-20"></div>
                <div className="text-align-center">
                    <img src="https://i.imgur.com/iK2GkOm.jpg" style={{maxWidth: "100%", maxHeight:"100%", objectFit: "cover"}}/> 
                    </div>                
               </div>
                </div>
            </div>

            <Footer/>     
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return{
       isLoggedIn: state.authReducer.isLoggedIn,
       loggedInUser: state.authReducer.user
    };
    
};

const mapDispatchToProps = {
    logout
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaClockIn);